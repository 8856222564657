import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiLoginPath, apiLogoutPath, apiRegisterPath } from '../common/api';
import { MODULE_NAME } from './sessionsSlice';

export interface LoginUserRequest {
  login: string;
  password: string;
}

export interface ApiResponseData {

}

export interface ApiError {
  code: number;
  message: string;
  errors: any;
}

export interface ApiResponse {
  status: number;
  error?: ApiError;
  data: ApiResponseData | any;
}

const fromResponse = (response: Response, parsed: any): ApiResponse => {
  if (parsed.ok) {
    return {
      status: response.status,
      error: undefined,
      data: parsed
    }; 
  } else {
    return {
      status: response.status,
      data: parsed.data,
      error: undefined
    };
  }
};

const withError = (status: number): ApiResponse => ({
  status: status,
  data: {},
  error: {
    code: -1,
    message: "Error while fetching data",
    errors: {}
  }
});


export const apiPost = async (path: string, request: any): Promise<ApiResponse> => {
  try {
    const response = await fetch(path, {
      method: RequestMethod.Post,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request)
    });
    if (response.ok) {
      const parsed = await response.json();
      return fromResponse(response, parsed);
    } else {
      return withError(response.status)
    }
  } catch (e) {
    return withError(0)
  }
}


export enum RequestMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Patch = "PATCH",
  Delete = "DELETE"
}

export interface RegisterUserRequest {
  fullName: string;
  email: string;
  login: string;
  password: string;
}