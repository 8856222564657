import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import postsReducer from './app/posts/postsSlice';
import sessionsReducer from './app/sessions/sessionsSlice';

export const store = configureStore({
  reducer: {
    posts: postsReducer,
    sessions: sessionsReducer
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
