import * as collection from '../../utils/collection';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllPosts } from './postsApi';
import { Post, PostId, VoteType } from './post';
import { PostsLoadingState, PostsState } from './state';

const MODULE_NAME = 'posts';

const initialState: PostsState = {
  status: PostsLoadingState.Loading,
  posts: collection.fromArray()
};

export const fetchPosts = createAsyncThunk(
  `${MODULE_NAME}/fetchAll`, async () => {
    const response = await fetchAllPosts(1, 0);
    return response;
  }
);

const postsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    create: (state, action: PayloadAction<Post>) => {
      state.posts = collection.append(state.posts, action.payload);
    },
    upVotePost: (state, action: PayloadAction<{ id: PostId }>) => {
      const { id } = action.payload;
      const post = collection.getById(state.posts, id);
      state.posts = collection.update(state.posts, {
        id: id,
        votes: (post?.votes || 0) + 1,
        voteType: VoteType.Up
      });
    },
    downVotePost: (state, action: PayloadAction<{ id: PostId }>) => {
      const { id } = action.payload;
      const post = collection.getById(state.posts, id);
      state.posts = collection.update(state.posts, {
        id: id,
        votes: (post?.votes || 0) - 1,
        voteType: VoteType.Down
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = PostsLoadingState.Loading;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = PostsLoadingState.Loaded;
        state.posts = collection.fromArray(action.payload);
      })
  }
});

export const { create } = postsSlice.actions;

export default postsSlice.reducer;
