import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionsState } from './state';
import { apiPost, ApiResponse, LoginUserRequest, RegisterUserRequest, RequestMethod } from './actions';
import { apiLoginPath, apiLogoutPath, apiRegisterPath } from '../common/api';

export const MODULE_NAME = 'session';

const initialState: SessionsState = {
  accessToken: "",
  login: "",
  email: "",
  isLoggedIn: false
}

export const loginUser = createAsyncThunk(
  `${MODULE_NAME}/login`, async (request: LoginUserRequest, thunkApi) => {
    thunkApi.dispatch(setLogin({ loginOrEmail: request.login }));
    const response = await apiPost(apiLoginPath(), {
      "user": { "email": request.login, "password": request.password }
    });

    return response;
  }
);

export const logoutUser = createAsyncThunk(
  `${MODULE_NAME}/logout`, async () => {
    return await apiPost(apiLogoutPath(), {});
  }
);

export const registerUser = createAsyncThunk(
  `${MODULE_NAME}/register`, async (request: RegisterUserRequest) => {
    const response = await apiPost(apiRegisterPath(), {
      "user": request
    })
    return response;
  }
)

interface SetLoginAction {
  loginOrEmail: string;
}

const sessionsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<SetLoginAction>) => {
      const { loginOrEmail } = action.payload;
      if (loginOrEmail.includes('@')) {
        return { ...state, email: loginOrEmail }
      } else {
        return { ...state, login: loginOrEmail }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
        console.log("login user:" + action.payload);
        return {
          ...state,
          accessToken: action.payload.data.accessToken,
          isLoggedIn: true
        }
      })
      .addCase(registerUser.pending, (state) => {
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        console.log("register user:" + action.payload)
      })
  }
});

export const { setLogin } = sessionsSlice.actions;
//export const { loginUser, registerUser, logoutUser } = sessionsSlice;

export default sessionsSlice.reducer;
