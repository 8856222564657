import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Posts from './app/posts/Posts';
import Navbar from './app/nav/Navbar';
import PageContainer from './app/main/PageContainer';
import ContentContainer from './app/main/ContentContainer';
import LeftContentContainer from './app/main/LeftContentContainer';
import CenterContentContainer from './app/main/CenterContentContainer';
import AppRoutes from './AppRoutes';
import Login from './app/sessions/login/LoginPage';
import Sidebar from './app/sidebar/Sidebar';
import Header from './app/main/Header';
import RightContentContainer from './app/main/RightContentContainer';
import MainContentContainer from './app/main/MainContentContainer';
import QliqSidebar from './app/right-sidebars/QliqSidebar';
import Keycloak, { KeycloakAdapter, KeycloakLoginOptions, KeycloakLogoutOptions, KeycloakPromise, KeycloakRegisterOptions } from 'keycloak-js';
import LandingPage from './landing/main/LandingPage';

require('./App.scss');

const QliqSessionAdapter: KeycloakAdapter = {
  login: (options: KeycloakLoginOptions): any => {
    console.log('login');
    console.log(options);
    //Promise.resolve(() => new KeycloakPromise())
    return new Promise<boolean>((resolve, reject) => 
      resolve(true)
    );
  },

  logout: (options?: KeycloakLogoutOptions): any => {
    console.log('logout');
    console.log(options);
    /*
    return new Promise<boolean>((resolve, reject) => 
      resolve(true)
    );
    */
    return new Promise<boolean>((resolve, reject) => 
      resolve(true)
    );
  },
  register: (options?: KeycloakRegisterOptions): any => {
    console.log('register');
    console.log(options);
    return new Promise<boolean>((resolve, reject) => 
      resolve(true)
    );
  },
  accountManagement: (): any => {
    console.log('account man');
    return new Promise<boolean>((resolve, reject) => 
      resolve(true)
    );
  },
  redirectUri: (options: { redirectUri: string; }, encodeHash: boolean) => {
    return ''
  }
}

const keycloak = Keycloak({ clientId: 'qliq_site', realm: 'main', url: 'http://accounts.zarja.local:8080/auth' });
keycloak.init({
//  adapter: QliqSessionAdapter
 // adapter: KeycloakSession
})
console.log("keycloak:", keycloak);
(window as any).keycloak = keycloak;

const App = () => {
  useEffect(() => {
    keycloak
      .loadUserInfo()
      .then(x => console.log(x))
  }, [])

  const isLoggedIn = false;
  if (isLoggedIn && window.location.pathname === '/') {
    return (
      <BrowserRouter>
          <Navbar />
          <Header />
          <PageContainer>
            <ContentContainer>
              <LeftContentContainer>
                <Sidebar />
              </LeftContentContainer>
              <CenterContentContainer>
                <MainContentContainer>
                  <AppRoutes />
                </MainContentContainer>
              </CenterContentContainer>
              <RightContentContainer>
                <QliqSidebar />
              </RightContentContainer>
            </ContentContainer>
          </PageContainer>
      </BrowserRouter>
    );
  }
  
  return <LandingPage />;
}

export default App;
