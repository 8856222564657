import { isInDevelopment } from "./environments"

export const APP_SITE_PREFIX = 'site'

export const sitePath = (uri: string = '') => `/${APP_SITE_PREFIX}/${uri}`

export const loginPath = () => sitePath('login')
export const logoutPath = () => sitePath('logout')

export const signUpPath = () => sitePath('sign_up')
export const forgotPasswordPath = () => sitePath('password/new')
export const resendConfirmationPath = () => sitePath('confirmation/new')
export const unlockPath = () => sitePath('unlock/new')

export const imageAssetPath = (name: string) => sitePath('assets/images/' + name);