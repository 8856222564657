import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { loginPath, signUpPath } from '../common/routes';
import BrandImage from './BrandImage';
import CollapseButton from './CollapseButton';
import NavLink from './NavLink';

require('./Navbar.scss');

const Navbar: FC = () =>
  <nav id='app-navbar' className='navbar navbar-expand-md navbar-dark shadow sticky-top'>
    <div className='collapse navbar-collapse'>
      <ul className='navbar-nav mr-auto'>
        <BrandImage />
        <CollapseButton />
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav mr-auto'>
            <a href='#' onClick={() => (window as any).keycloak.login() }>Login</a>
            <a href='#' onClick={() => (window as any).keycloak.register() }>Sign up</a>
            <NavLink to='/site/posts'>All posts</NavLink>
          </ul>
        </div>
      </ul>
    </div>
    <div className="navbar-pullright">
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav mr-auto'>
            {(window as any)?.keycloak?.userInfo?.preferred_username &&
              <NavLink to={`/@${(window as any).keycloak.userInfo.preferred_username}`}>@{(window as any).keycloak.userInfo.preferred_username} [{(window as any).keycloak.userInfo.name}]</NavLink>
            }
          </ul>
        </div>
    </div>
  </nav>;

export default Navbar;