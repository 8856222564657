import axios from 'axios';
import { Post, VoteType } from './post';

const mapVote = (voteType: string): VoteType => {
  if (voteType === 'up')
    return VoteType.Up;

  if (voteType === 'down')
    return VoteType.Down;

  return VoteType.None;
}

const mapPost = (postData: any): Post => {
  return {
    id: postData?.id || '', 
    slug: postData?.attributes?.slug || '',
    title: postData?.attributes?.title || '',
    content: postData?.attributes?.content || '',
    votes: postData?.attributes?.voteCount || '',
    voteType: mapVote(postData?.attributes?.currentType),
    createdAt: postData?.attributes?.createdAt || new Date(),
    updatedAt: postData?.attributes?.updatedAt || new Date()
  };
}

const mapPosts = (postsData: any): Post[] => {
  if (postsData.data) {
    return postsData.data.map(mapPost);
  }
  return [];
}

//const BASE_URL = 'https://test-acc-de.qliq.hu';
const BASE_URL = 'http://localhost:3000';
//const BASE_URL = 'https://api.mockaroo.com/api/d2109b90?count=1000&key=fd8f05e0';
export const fetchAllPosts = async (limit: number, page: number): Promise<Post[]> => {
  const url = `${BASE_URL}/site/posts.json`;
  console.log(`Fetch ${url}, limit: ${limit}, page: ${page}`);

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return mapPosts(response.data);
    } else {
      throw('Error while fetching posts!');
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error)
  }
};