import { isInDevelopment } from "./environments"

const PROD_BASE_URL = 'qliq.site';
const PROD_URL = `https://${PROD_BASE_URL}`;
const SSO_PROD_URL = `https://accounts.${PROD_BASE_URL}`;

const API_V1 = 'api/v1';
const DEV_PORT = 4000;

const AUTH_DEV_PORT = 8080;

export const apiRealm = () => {
  const { hostname } = window.location;
  const domains = hostname.split('.');
  const count   = domains.length
  if (count > 2) {
    const realm = domains[count - 3]
    if (realm === 'accounts' || realm === 'www')
      return 'main';

    return realm;
  }

  return 'main';
};

export const apiAuthUrl = (realm: string) => {
  const uri = '';
  if (isInDevelopment()) {
    return `${window.location.protocol}//${window.location.hostname}:${DEV_PORT}/${API_V1}${uri}`
  }
  return `${SSO_PROD_URL}`;
}

export const apiPath = (uri: string = '') => {
  const r = isInDevelopment()
    ? `${window.location.protocol}//${window.location.hostname}:${DEV_PORT}/${API_V1}${uri}`
    : `https://qliq.site/${API_V1}${uri}`;
  console.log("url: " + r);
  return r;
};

export const apiLoginPath = () =>
  apiPath('/session');

export const apiLogoutPath = () =>
  apiPath('/session');

export const apiRegisterPath = () =>
  apiPath('/register');