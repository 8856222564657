import React, { FC, PropsWithChildren, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../../store';
import { forgotPasswordPath, resendConfirmationPath, signUpPath, unlockPath } from '../../common/routes';
import { loginUser } from '../sessionsSlice';

interface OwnProps {
  //login: (loginOrEmail: string, password: string, rememberMe: boolean) => void;
}

interface DispatchProps {
  login: (loginOrEmail: string, password: string) => void;
}

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  login: (login: string, password: string) => dispatch(loginUser({login, password}))
});

type LoginProps = OwnProps & DispatchProps;
const LoginPage: FC<LoginProps> = (props: LoginProps) => {
  const { login } = props;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  return <Col lg={ 12 }>
    <Col className='mt-4 mb-4 m-3'>
      <Row>
        <Col lg={ 12 } className='ml-auto mr-auto mb-4 mt-2'>
          <h1>Login</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={ 12 } className='ml-auto mr-auto'>
          <form className='new_user' id='new_user'>
            <div className='form-group mb-3'>
              <input
                id='user_name'
                name='user[password]'
                className='form-control form-control-md'
                type='text'
                autoFocus
                autoComplete='username'
                placeholder='User name or email'
                value={ userName }
                onChange={(e) => setUserName(e.target.value)} />
            </div>
            <div className='form-group mb-3'>
              <input
                id='user_password'
                type='password'
                name='user[password]'
                autoComplete='password'
                placeholder='Password'
                className='form-control form-control-md'
                value={ password }
                onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='form-group mb-3'>
              <div className='form-check'>
                <input name='user_remember_me' type='hidden' value='0' />
                <input className='form-check-input' id='login-remember-me' name='user_remember_me' type='checkbox' value='1' />
                <label className='form-check-label'>Remember me</label>
              </div>
            </div>
            <div className='form-group mb-3'>
              <Button onClick={() => login(userName, password)}>Login</Button>
            </div>
          </form>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className='ml-auto mr-auto'>
          <ul className='list-unstyled'>
            <li>
              <Link to={signUpPath()}>Sign up</Link>
            </li>
            <li>
              <Link to={forgotPasswordPath()}>Forgot your password?</Link>
            </li>
            <li>
              <Link to={resendConfirmationPath()}>Didn't receive confirmation instructions?</Link>
            </li>
            <li>
              <Link to={unlockPath()}>Didn't receive unlock instructions?</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Col>
  </Col>
};

//export default connect(null, mapDispatchToProps)(LoginPage);
export default connect(null, mapDispatchToProps)(LoginPage);