export type PostId = string;
export enum VoteType { None, Up, Down };

export interface Post
{
  id: PostId;
  title: string;
  slug: string;
  content: string;
  createdAt: Date
  updatedAt: Date
  votes: number;
  voteType: VoteType;
}
