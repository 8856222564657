import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import SidebarItem from './SidebarItem';

import { faHome, faFire, faRandom, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

require('./Sidebar.scss');

const Sidebar: FC = () => {
  return <Row>
    <Col className='sidebar-content p-0'>
      <ul className='main-sidebar nav flex-column'>
        <SidebarItem text="Home" to="/" icon={faHome} />
        <SidebarItem text="Subscriptions" to="/" icon={faThumbsUp} />
        <SidebarItem text="Trending" to="/"  icon={faFire} />
        <SidebarItem text="Random Qliq" to="/" icon={faRandom} />
      </ul>
    </Col>
  </Row>;
};

export default Sidebar;

