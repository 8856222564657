import { connect } from "react-redux";
import { RootState } from "../../store";
import * as collection from '../../utils/collection';

interface OwnProps {
  id: string;
}

interface PostProps {
  title: string;
  content: string;
  slug: string;
}

export const Post = ({ title, content, slug }: OwnProps & PostProps) => {
  return <article>
    <h3>
      <a href={slug}>
        {title}
      </a>
    </h3>
    <p>{content}</p>
  </article>
};

const mapStateToProps = ((state: RootState, ownProps: OwnProps) => {
 const post = collection.getById(state.posts.posts, ownProps.id);

 return ({
   title: post?.title || '',
   content: post?.content || '',
   slug: post?.slug || ''
 });
});

export default connect(mapStateToProps)(Post);