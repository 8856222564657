import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { imageAssetPath } from '../common/routes';

require('./Header.scss');

interface OwnProps {}

type HeaderProps = OwnProps & PropsWithChildren<ReactNode>;
const Header: FC<HeaderProps> = (_) => {
  const style = { backgroundImage: `url(${ imageAssetPath('header-image.svg') })`}
  return <header className="w-100 shadow-sm" style={ style }>
    <Link to="/">
      <img id="header-logo" src={ imageAssetPath('qliq-filled-inverse.svg') } />
    </Link>
  </header>;
};

export default Header;