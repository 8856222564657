import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

require('./Navbar.scss');

interface NavLinkProps
{
  className?: string;
  isDisabled?: boolean;
  to: string;
}

type NavLinkInput =  PropsWithChildren<NavLinkProps>;
const NavLink: FC<NavLinkInput> = ({ className, isDisabled, to, children}: NavLinkInput) =>
  <li className='navbar nav-item'>
    <Link className={className || ''} to={to}>{children}</Link>
  </li>;

const Navbar: FC = () => {
  const signUpStyle: React.CSSProperties = {
    fontWeight: 700
  }

  return <nav id="landing-navbar" className='navbar navbar-expand-md sticky-top'>
    <div className='collapse navbar-collapse'>
      <ul className='navbar-nav mr-auto'>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav mr-auto'>
            <a href='#' onClick={() => (window as any).keycloak.login() }>Home</a>
            <a href='#' onClick={() => (window as any).keycloak.register() }>Features</a>
            <a href='#' onClick={() => (window as any).keycloak.register() }>Testimonials</a>
            <a href='#' onClick={() => (window as any).keycloak.register() }>Plans</a>
          </ul>
        </div>
      </ul>
    </div>
    <div className="navbar-pullright">
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav mr-auto'>
            <a id="signup-button" href='#' >Sign up for Free!</a>
          </ul>
        </div>
    </div>
  </nav>;
}

export default Navbar;