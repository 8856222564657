import React, { FC, useEffect } from 'react';
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import * as collection from '../../utils/collection';
import Post from './Post';
import { fetchPosts } from './postsSlice';

interface PostsProps {
  ids: string[];
}

interface PostsDispatch {
  fetchPosts: () => void;
}

export const Posts: FC<PostsProps & PostsDispatch> = ({ ids, fetchPosts }: PostsProps & PostsDispatch) => {
  useEffect(() => {
    fetchPosts();
  }, [fetchPosts])

  return <>
    {
      ids.map(id => <Post id={id} key={id}/>)
    }
  </>;
};

const mapStateToProps = ((state: RootState) => ({
  ids: collection.getIds(state.posts.posts)
}));

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPosts: () => dispatch(fetchPosts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Posts);