import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStore } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import HeroBackground from '../assets/images/HeroBackground';
import Navbar from '../nav/Navbar';

require('./LandingPage.scss');

interface TitleProps extends PropsWithChildren<any> {
  fontSize?: number;
  color?: string;
  weight?: number;
}
const Title = (props: TitleProps) => {
  const titleStyle: React.CSSProperties = {
    fontSize: `${props.fontSize || 60}pt`,
    //color: props.color || '#fff',
    fontWeight: props.weight || 700
  };

  return <h2 style={titleStyle}>{props.children}</h2>
};

interface HeroTitleProps extends PropsWithChildren<any> {
  style?: React.CSSProperties
  className?: string;
}

const HeroTitle = (props: HeroTitleProps) => {
  return <div style={props.style || {}} className={props.className || ''}>
    <Title fontSize={60} weight={700}>
      Social Site for Your Business
    </Title>
    <Title fontSize={60} weight={300}>
      As You Know It
    </Title>
  </div>;
}

interface PageContainerProps extends React.PropsWithChildren<any> {
  backgroundColor?: string;
  color?: string;
  id?: string;
}

const PageContainer = (props: PageContainerProps) => {
  const backgroundColor = props.backgroundColor || "#000"
  const color = props.color || "#fff"
  return <div id={props.id} className="page-container page-size" style={{ backgroundColor, color }}>
    {props.children}
  </div>;
};

export const LandingPage = () => {
  const onResize = useCallback((a:any) => {
    //setWidth(window.innerWidth);
    //setWidth(window.innerHeight);
    let root = document.documentElement;
    //root.style.setProperty('--window-height', `${window.innerHeight}px`)
    //root.style.setProperty('--window-width', `${window.innerWidth}px`)
  }, [])

  useEffect(() => {
    onResize(null);
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize])
  
  // <HeroBackground />
  return <BrowserRouter>
      <Navbar />
        <Container fluid style={{ padding: 0 }}>
          <PageContainer id="intro" backgroundColor='#8d39be' color='#eee'>
            <Col lg={12}>
              <Row>
                <HeroTitle style={{ marginLeft: "0px", marginTop: "0px" }} />
              </Row>
            </Col>
          </PageContainer>
          <PageContainer id="third" backgroundColor='#7489c7' color='#eee'>
            <Col lg={12}>
              <Row>
                <Title fontSize={60} weight={600}>
                  Even this is cool
                </Title>
              </Row>
            </Col>
          </PageContainer>
          <PageContainer id="second" backgroundColor='#ceaf36' color='#eee'>
            <Col lg={12}>
              <Row>
                <Title fontSize={60} weight={600}>
                  Check out this
                </Title>
              </Row>
            </Col>
          </PageContainer>
          <PageContainer id="fourth" backgroundColor='#ce3689' color='#eee'>
            <Col lg={12}>
              <Row>
                <Title fontSize={60} weight={600}>
                  This is the end I guess?
                </Title>
              </Row>
            </Col>
          </PageContainer>
          <PageContainer id="fifth" backgroundColor='#50a288' color='#eee'>
            <Col lg={12}>
              <Row>
                <Title fontSize={60} weight={600}>
                  Not yet!
                </Title>
              </Row>
            </Col>
          </PageContainer>
      </Container>
  </BrowserRouter>
};

export default LandingPage;